#column-left:not(.display-column),
#column-right:not(.display-column) {
    @media (max-width: $break-lg-max) {
        display: none;
    }
}

#column-left,
#column-right {
    > * + * {
        margin-top: 1rem;
    }
}

.ds-column {
    &-categories {
        gap: 0.25rem;

        &-list {
            gap: 0.25rem;
        }

        &-item {
            color: var(--ds-dark-text-color);
            padding: 0.75rem;
            font-size: 0.75rem;

            >*:not(ul) {
                cursor: pointer;
                transition: background-color var(--ds-main-transition);
            }

            &.clicked {
                > span {
                    font-weight: 600;

                    >.ds-column-categories-toggle {
                        transform: rotate(180deg);
                        background: #F2F2F2;

                        svg path {
                            fill: var(--ds-secondary-dark-color);
                        }
                    }
                }
            }

            a:hover {
                text-decoration: underline;
                color: var(--ds-links-color);
            }
        }

        &-toggle {
            width: 1rem;
            height: 1rem;
            filter: none;
            background: var(--ds-white-color);
            transition: transform var(--ds-main-transition);
            display: grid;
            place-items: center;

            &:hover {
                background: #F2F2F2;

                svg path {
                    fill: var(--ds-secondary-dark-color);
                }
            }
        }

        &-children {
            max-height: 0;
            overflow-y: hidden;
            transition: max-height 0.4s ease-in-out 0.05s, padding 0.4s ease-in-out 0.05s;

            &.ds-categories-2 {
                font-weight: 400;

                a {
                    color: var(--ds-secondary-text-color);

                    &:hover {
                        color: var(--ds-links-color);
                    }
                }

                li {
                    padding: 0.5rem 0 0.5rem 1rem;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            &.active {
                padding-top: 0.25rem;
                max-height: 3000px;
            }
        }

        .ds-categories-3 {
            li {
                &:hover a {
                    color: var(--ds-links-color);
                }
            }
        }

        a.current {
            color: var(--ds-links-color);
            font-weight: 600;
        }
    }

    &-title {
        border-bottom: var(--ds-main-border);
        line-height: 1;
    }

    &-products {
        &:not(.slick-initialized) {
            display: flex;
            max-width: 100%;
            overflow-x: hidden;
            align-items: stretch;
            flex-wrap: nowrap;

            .ds-module-item {
                flex: 0 0 100%;
                height: auto;
                width: 100%;
            }
        }

        .ds-column-item {
            flex: 0 0 100%;
            display: none;
            min-height: 440px;
        }
    }



    .slick {
        &-track {
            display: flex;
            align-items: stretch;
        }

        &-slide {
            height: auto !important;
        }

        &-slider {
            position: static;
            
            &-btn {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 2;
                opacity: 0.5;
        
                &:hover {
                    opacity: 1;
                }
    
                &-prev {
                    left: 1rem;
                }
    
                &-next {
                    right: 1rem;
                }
            }
        }
    }

    &-banners {
        &:not(.slick-initialized) {
            display: flex;
            max-width: 100%;
            overflow-x: hidden;
            align-items: stretch;

            .ds-bannerplus-item {
                flex: 0 0 100%;
                height: auto;
            }
        }

        &.slick-initialized {
            .slick-track {
                display: flex;
                align-items: stretch;

                .ds-bannerplus-item {
                    height: auto !important;
                }
            }
        }
    }

    &-shop-reviews {
        .ds-product-reviews-item {
            background: var(--ds-white-color);
        }
    }

    .ds-module-item:not(.product-layout) {
        width: 100%;
    }
}