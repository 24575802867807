.ds-menu {
    &-maincategories {
        &-item {
            letter-spacing: 0.0225rem;

            @media (min-width: $break-xl) {
                > svg {
                    transform: rotate(90deg);
                    transition: transform var(--ds-main-transition);
    
                    path {
                        transition: fill var(--ds-main-transition);
                    }
                }
    
                &:hover {
                    > span a {
                        color: var(--ds-links-color);
                        text-decoration: underline;
                    }
    
                    > .ds-menu-maincategories-dropdown {
                        opacity: 1;
                        visibility: visible;
                        top: calc(100% - 0.125rem);
                    }
    
                    > svg {
                        transform: rotate(270deg);
    
                        path {
                            fill: var(--ds-links-color);
                        }
                    }
                }
            }
        }

        &-desktop-box {
            
            .ds-menu-maincategories {
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: none;
                scrollbar-width: none;
    
                &::-webkit-scrollbar {
                    display: none;
                }

                border-top: var(--ds-main-border);
                gap: 0.5rem;
                height: 100%;

                &::after {
                    content: "";
                    position: absolute;
                    top: 1px;
                    right: 0;
                    bottom: 0;
                    width: 128px;
                    background: var(--ds-maincategories-gradient);
                    pointer-events: none;
                }

                &.hide-gradient::after {
                    display: none;
                }

                &-item {
                    white-space: nowrap;
                }

                &-dropdown {
                    @media (max-width: $break-lg-max) {
                        transition: transform var(--ds-main-transition), box-shadow var(--ds-main-transition);
                        max-height: 100vh;
                        max-width: 414px;
                        overflow: hidden;
                        bottom: 0;
                        position: absolute;
                        z-index: 13024;
                        top: 0;
                        left: 0;
                        width: 100%;
                        background: var(--ds-light-bg-color);
                        box-shadow: 0px 2px 14px 0px rgba(37, 38, 39, 0);
                        transform: translateX(-100%);
                    }
                    
                    top: -9999px;
                    right: 0.75rem;
                    left: 0.75rem;
                    z-index: 13024;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity var(--ds-main-transition) 0.05s, visibility var(--ds-main-transition) 0.05s;
                    background: var(--ds-white-color);
                    box-shadow: var(--ds-medium-shadow);
                    border-radius: 0 0 0.5rem 0.5rem;
                    max-height: 100vh;
    
                    &-narrow {
                        right: auto;
                        left: 0;
                        width: 342px;

                        &.ds-menu-maincategories-dropdown-blog {
                            width: 200px;
                        }
    
                        li:hover {
                            background: #FCFCFC;
                        }
    
                        .ds-menu-catalog-inner {
                            position: relative;
                            height: 100%;
                            border-left: var(--ds-main-border);
                        }
    
                        .ds-menu-catalog {
                            transform: translateX(calc(100% - 1px));
                            opacity: 0;
                            visibility: hidden;
                            transition: opacity var(--ds-main-transition) 0.05s, visibility var(--ds-main-transition) 0.05s;
                            bottom: 0;
                            background: var(--ds-white-color);
    
                            ul {
                                border-left: 0;
                            }
                        }
    
                        .ds-menu-catalog-items {
                            max-height: calc(100vh - 7rem);
                            overflow-y: auto;
                        }
    
                        .ds-menu-catalog-item:hover {
                            > .ds-menu-catalog {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
    
                    .ds-megamenu-child-wrapper {
                        max-height: calc(100vh - 8rem);
                        overflow-y: auto;
                    }
    
                    .ds-megamenu-children {
                        gap: 1.5rem;
    
                        &-item {
                            flex: 0 0 calc(25% - 1.25rem);

                            @media (min-width: $break-xxl) {
                                flex: 0 0 calc(16.6666666667% - 1.25rem);
                                max-width: calc(16.6666666667% - 1.25rem);
                            }
            
                            a {
                                white-space: normal;
                                line-height: 1.12;
                            }
                        }
    
                        &-manufacturers {
                            .ds-megamenu-children-item {
                                flex: 0 0 auto;
                                width: calc(100% / 8);
    
                                img {
                                    object-fit: contain;
                                    opacity: 0.95;
                                    transition: opacity var(--ds-main-transition);
    
                                    &:hover {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
    
                    a:hover {
                        color: var(--ds-links-color);
                        text-decoration: underline;
                    }
                }
            }
        }
        
        a:hover {
            color: var(--ds-links-color);
            text-decoration: underline;
        }
    }

    &-catalog {
        @media (max-width: $break-lg-max) {
            transition: transform var(--ds-main-transition), box-shadow var(--ds-main-transition);
            max-height: 100vh;
            max-width: 414px;
            overflow: hidden;
            bottom: 0;
        }
        position: absolute;
        z-index: 13024;
        top: 0;
        left: 0;
        width: 100%;
        background: var(--ds-light-bg-color);
        box-shadow: 0px 2px 14px 0px rgba(37, 38, 39, 0);
        transform: translateX(-100%);

        @media (min-width: $break-xl) {
            width: 100%;

            .ds-sidebar-header {
                display: none !important;
            }

            &-items {
                background: var(--ds-white-color);
                border: var(--ds-main-border);
            }
        }

        &.ds-menu-main-catalog {
            position: fixed;

            &:hover {
                overflow: visible;
            }

            @media (min-width: $break-xl) {
                position: absolute;
                transform: translateX(0);
                top: 52px;
                opacity: 0;
                visibility: hidden;
                overflow: hidden;

                &.active,
                &.with-slideshow {
                    opacity: 1;
                    visibility: visible;
                    transition: opacity var(--ds-main-transition) 0.05s, visibility var(--ds-main-transition) 0.05s;
                }

                &.with-slideshow {
                    top: calc(100% + 2rem);
                    max-height: 610px;
                }

                .ds-menu-catalog-item:not(:first-child) {
                    border-top-color: transparent;
                }

                .ds-menu-catalog-item:hover>.ds-menu-catalog {
                    opacity: 1;
                    visibility: visible;
                    overflow: visible;
                }

                > .ds-menu-catalog-inner {
                    max-height: calc(100vh - 5.25rem);
                    overflow-y: auto;
                    
                    > .ds-menu-catalog-items {
                        > li:hover {
                            background: #FCFCFC;

                            > .ds-menu-catalog-item-title a {
                                color: var(--ds-links-color);
                                text-decoration: underline;
                            }

                            .ds-menu-catalog-wide {
                                z-index: 13024;
                                top: 0;
                                max-height: calc(100vh - 5.25rem);
                                overflow-y: auto;
                                border-bottom-left-radius: 0;
                            }
                        }
                    }
                }

                .ds-menu-catalog {
                    bottom: 0;
                    opacity: 0;
                    transform: translateX(100%);
                    visibility: hidden;
                    background: var(--ds-white-color);
                    transition: opacity var(--ds-main-transition) 0.05s, visibility var(--ds-main-transition) 0.05s;

                    .ds-menu-catalog-inner {
                        height: 100%;
                        overflow-y: auto;
                    }

                    .ds-menu-catalog-items {
                        border-left: 0;
                        min-height: 100%;
                    }

                    .ds-menu-catalog-item:not(:first-child) {
                        border-top: var(--ds-main-border);
                    }

                    a:hover {
                        color: var(--ds-links-color);
                        text-decoration: underline;
                    }
                    
                    li:hover {
                        background: #FCFCFC;
                    }
                }
            }

            .ds-menu-catalog-wide {
                @media (min-width: $break-xl) {
                    width: 300%;
                    transform: translateX(33.3333%);
                    background: var(--ds-white-color);
                    overflow-y: auto;
                    border: var(--ds-main-border);
                    border-left: 0;
                    z-index: -1;
                    padding: 1rem;
                    border-radius: 0 0 0.5rem 0.5rem;
    
                    @media (min-width: $break-xxl) {
                        padding: 1.5rem;
                    }
    
                    li:hover {
                        background: transparent !important;
                    }
    
                    .ds-menu-catalog-inner {
                        height: auto !important;
                        display: flex;
                    }
    
                    .ds-menu-catalog-items {
                        border: 0;
                    }
    
                    .ds-menu-catalog-item {
                        padding: 0.125rem 0;
                        border-top: 0 !important;
                        flex-direction: column;
                        align-items: flex-start !important;
                        justify-content: flex-start !important;
                        color: var(--ds-secondary-dark-color);
                        font-weight: 400;
    
                        svg {
                            display: none;
                        }
                    }
    
                    .ds-menu-catalog {
                        opacity: 1;
                        visibility: visible;
                        position: static;
                        width: auto;
                        transform: translateX(0);
                        background: transparent;
                        padding-top: 0.5rem;
    
                        .ds-sidebar-header {
                            display: none !important;
                        }
                    }
    
                    > .ds-menu-catalog-inner > .ds-menu-catalog-items {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        gap: 1rem;
                        width: 100%;
                        list-style: none;
    
                        .ds-menu-list-landings ul {
                            padding: 0;
                        }
    
                        > li {
                            width: calc(25% - 1rem);
    
                            > a {
                                font-size: 1rem;
                                color: var(--ds-dark-text-color);
                                line-height: 1.2;
                                align-items: center;
                                font-size: 0.875rem;
                                font-weight: 600;
                                padding-bottom: 1rem;
                                border-bottom: var(--ds-main-border);
                                margin-bottom: 0.5rem;
                                align-self: stretch;
                            }
                        }
                    }
                }
            }
        }

        &.active {
            @media (max-width: $break-lg-max) {
                box-shadow: var(--ds-medium-shadow);
            }
            transform: translateX(0);
            transition: transform var(--ds-main-transition), box-shadow var(--ds-main-transition);
        }

        &-item {
            padding: 0.75rem 1rem;

            &:not(:first-child) {
                border-top: var(--ds-main-border);
            }

            &-title {
                img {
                    margin-right: 1rem;
                }
            }

            > span + svg,
            &-title + svg,
            a + svg {
                path {
                    fill: var(--ds-dark-text-color);
                }
            }
        }

        &-inner {
            @media (max-width: $break-lg-max) {
                height: calc(100% - 56px);
                overflow-x: hidden;
                overflow-y: auto;
                scroll-behavior: smooth;
            }

            a:hover {
                color: var(--ds-links-color);
                text-decoration: underline;
            }
        }

        @media (min-width: $break-xl) {
            &-items {
                background: var(--ds-white-color);
                border: var(--ds-main-border);
            }

            .ds-menu-maincategories-dropdown {
                transition: transform var(--ds-main-transition), box-shadow var(--ds-main-transition);
                max-height: 100vh;
                max-width: 414px;
                overflow: hidden;
                bottom: 0;
                position: absolute;
                z-index: 13024;
                top: 0;
                left: 0;
                width: 100%;
                background: var(--ds-light-bg-color);
                box-shadow: 0px 2px 14px 0px rgba(37, 38, 39, 0);
                transform: translateX(-100%);
            }
        }
    }

    &-list-landings {
        ul {
            padding: 1rem;
        }

        ul:hover,
        li:hover,
        &:hover {
            background: var(--ds-white-color) !important;
        }

        li + li {
            margin-top: 0.5rem;
        }

        .ds-megamenu-children-title {
            margin-bottom: 0.75rem;
            padding-bottom: 0.25rem;
        }
    }
}

.ds-megamenu {
    &-children {
        @media (max-width: $break-lg-max) {
            &:not(.ds-megamenu-children-manufacturers) {
                justify-content: space-between;
                gap: 1.5rem;
                padding: 1rem 0;

                .ds-megamenu-children-item {
                    flex: 0 0 calc(50% - 0.75rem);
                    max-width: calc(50% - 0.75rem);
                }
            }

            &-manufacturers {
                flex-wrap: nowrap !important;
                
                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    font-weight: 400;
                }

                a {
                    padding: 0.75rem 1rem !important;
                }

                > *:not(:last-child) {
                    border-bottom: var(--ds-main-border);
                }
            }
        }

        @media (min-width: $break-xl) {
            &-manufacturers {
                max-height: calc(100vh - 12rem);
                overflow-y: auto;
                
                .ds-megamenu-children {
                    &-item {
                        flex: 0 0 auto;
                        width: calc((100% / 8) - 0.875rem);
                        border: var(--ds-main-border);
                        border-radius: 0.5rem;
                
                        img {
                            object-fit: contain;
                            opacity: 0.9;
                            transition: opacity var(--ds-main-transition);
                        }
    
                        &:hover {
                            box-shadow: var(--ds-medium-shadow);
    
                            img {
                                opacity: 1;
                            }
                        }
                    }

                    &-title {
                        padding-bottom: 0;
                        border-bottom: 0;
                    }
                }
            }

            &-title {
                border-bottom: var(--ds-main-border);
                padding-bottom: 1rem;
            }
        }
    }

    &-child-wrapper {
        max-height: calc(100% - 53px);
        overflow-y: auto;
    }
}

