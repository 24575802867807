.ds-module {
    &-item {
        &:not(.product-layout) {
            width: 100%;

            @media (min-width: $break-sm) {
                width: calc(100% / 2);
            }

            @media (min-width: $break-md) {
                width: calc(100% / 3);
            }

            @media (min-width: $break-xl) {
                width: calc(100% / 5);
            }
        }

        &:hover {
            .ds-module-title {
                color: var(--ds-links-color);
            }
        }
    }

    &-img {
        gap: 0.5rem;
        position: relative;

        @media (min-width: $break-md) {
            .ds-module-stickers {
                position: absolute;
                z-index: 2;
                left: 0.25rem;
                top: 0.25rem;
            }
        }

        img {
            max-width: 100%;
            object-fit: contain;
            border-radius: 0.25rem;
            height: auto;
        }
    }

    &-stickers {
        gap: 0.25rem;
    }

    &-sticker {
        display: grid;
        place-items: center;
        color: var(--ds-white-color);
        line-height: 1;
        padding: 0.25rem 0.5rem;
        font-size: 0.5rem;

        @media (min-width: $break-xl) {
            font-size: 0.625rem;
        }

        &-purple {
            background: #A456E1;
        }

        &-green {
            background: var(--ds-green-color);
        }

        &-pink {
            background: #FF3975;
        }

        &-blue {
            background: #4526FF;
        }
    }

    &-code {
        font-size: 0.5rem;

        @media (min-width: $break-xl) {
            font-size: 0.625rem;
        }
    }

    &-stock {
        font-size: 0.625rem;

        @media (min-width: $break-xl) {
            font-size: 0.75rem;
        }
    }

    &-buttons {
        top: 0;
        right: 0;
        padding: 0.125rem;
        gap: 0.75rem;
        background: rgba(255, 255, 255, 0.77);
        z-index: 10;

        button {
            background: transparent;
            border: none;
            cursor: pointer;
            line-height: 1;
        }
    }

    &-button {
        &.added {
            svg {
                path {
                    fill: var(--ds-interactive-color);
                }
            }
        }
    }

    &-caption {
        gap: 0.5rem;
        padding-top: 0.5rem;

        @media (min-width: $break-sm) {
            height: 100%;
        }
    }

    &-title {
        font-size: 0.75rem;

        @media (min-width: $break-md) {
            font-size: 0.875rem;
        }

        &:hover {
            text-decoration: underline;
            color: var(--ds-links-color);
        }
    }

    &-rating {
        &-star {
            background-image: url("../../images/module-star-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 0.75rem;
            height: 0.75rem;
            position: relative;

            &-inner {
                position: absolute;
                top: 0;
                left: 0;
                width: 0; // Встановлюється динамічно через JS
                height: 100%;
                background-image: url("../../images/module-star-icon-is.svg");
                background-repeat: no-repeat;
                background-size: cover;
            }

            & + & {
                margin-left: 0.125rem;
            }

            &-is {
                background-image: url("../../images/module-star-icon-is.svg");
            }
        }
    }

    &-reviews {
        svg {
            margin-right: 0.125rem;
        }
    }

    &-price {
        line-height: 1;
    }

    &-quantity {
        border: var(--ds-main-border);
        background: var(--ds-light-bg-color);

        .form-control {
            flex: 0 0 2.5rem;
            max-width: 2.5rem;
            border: 0;
            border-radius: 0;
            padding: 0.25rem 0;
            text-align: center;
            line-height: 1.675;
            background: var(--ds-white-color);
            height: 100%;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        button {
            border: 0;
            padding: 0.5rem;
            background: transparent;
            flex: 1 1 2rem;
            width: 2rem;

            .plus {
                border-radius: 0 0.5rem 0.5rem 0;
            }

            .minus {
                border-radius: 0.5rem 0 0 0.5rem;
            }

            svg path {
                fill: var(--ds-secondary-dark-color);
            }
        }
    }

    &-cart {
        .button-outline {
            padding: 0.5rem;
            margin-left: auto;

            svg {
                margin-right: 0;
            }

            .button-text {
                display: none;
            }
        }

        &:not(.position-relative) {
            margin-top: 1rem;
        }
    }

    &-attributes {
        height: 0;
        top: 100%;
        left: -0.5rem;
        right: -0.5rem;
        border-radius: 0 0 7px 7px;
        background: var(--ds-white-color);
        opacity: 0;
        box-shadow: none;
        transition: opacity var(--ds-main-transition), box-shadow var(--ds-main-transition);
        visibility: hidden;
        padding: 0;
        overflow: hidden;

        &-item {
            gap: 0.25rem;

            & + & {
                margin-top: 0.375rem;
            }

            @media (min-width: $break-md) {
                > * {
                    line-height: 1.2;

                    &:last-child {
                        flex: 0 0 40%;
                        max-width: 40%;
                        margin-left: auto;
                    }
                }
            }
        }
    }

    &-showmore {
        svg {
            transition: transform var(--ds-main-transition) 0.1s;
        }

        button {
            @media (max-width: $break-xs-max) {
                width: 100%;
            }
            
            &:hover {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    @media (max-width: $break-xs-max) {
        &[data-type="width100"] {
            .ds-module-title {
                font-size: 0.875rem;
            }
        }

        &[data-type="width50"] {
            .ds-module-item {
                width: calc(100% / 2);
            }

            .content-block {
                padding: 0.5rem;
            }

            .ds-module-stickers:not(.ds-module-sticker-images) {
                position: absolute;
                z-index: 2;
                padding-right: 1rem;
                left: 0.25rem;
                top: 0.25rem;
            }
        }

        &[data-type="carousel"] {
            flex-wrap: nowrap;
            overflow-x: auto;

            .ds-module-item {
                width: 65%;
            }

            .ds-module-showmore {
                display: none !important;
            }

            .content-block {
                padding: 0.5rem;
            }
        }

        &[data-type="split"] {
            .content-block {
                padding: 0.5rem;
                flex-direction: row !important;
                position: relative;
            }

            .ds-module-caption {
                flex-grow: 1;
                padding-top: 0;
                padding-left: 0.5rem;
            }

            .ds-module-img {
                padding-right: 0.5rem;
                border-right: var(--ds-main-border);
                flex: 0 0 158px;

                img {
                    width: 150px;
                    height: 150px;
                }

                .ds-module-img-box {
                    order: 2;
                }

                .ds-module-stickers {
                    order: 1;
                }

                .ds-module-buttons {
                    left: 0;
                    right: auto;
                }
            }

            .ds-module-sticker-images {
                position: absolute;
                bottom: 0.75rem;
                left: 0.75rem;
            }

            .ds-module-cart {
                margin-top: auto;
            }
        }

        &[data-type="minimal"] {
            .content-block {
                padding: 0.5rem;
                flex-direction: row !important;
                position: relative;
            }

            .ds-module-caption {
                padding-top: 0;
                padding-left: 0.5rem;
                width: 100%;
            }

            .ds-module-img {
                padding-right: 0.5rem;
                border-right: var(--ds-main-border);
                flex: 0 0 96px;

                img {
                    width: 88px;
                    height: 88px;
                }
            }

            .ds-module-stickers,
            .ds-module-buttons,
            .ds-module-code,
            .ds-module-stock,
            .ds-module-rating,
            .ds-module-quantity {
                display: none !important;
            }

            .ds-module-cart {
                position: absolute;
                bottom: 0.5rem;
                right: 0.5rem;
            }
        }
    }
}