#overlay {
    background: rgba(17, 19, 20, 0);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;
    transition: background var(--ds-main-transition);

    &.active {
        background: var(--ds-popup-backdrop-bg);
        z-index: 12001;
        visibility: visible;

        &.transparent {
            background: transparent;
        }
    }

    &.active-sidebar {
        z-index: 14022;
    }
}

.ds-sidebar {
    position: fixed;
    z-index: 14023;
    top: 0;
    bottom: 0;
    left: 0;
    max-height: 100vh;
    width: 100%;
    max-width: 414px;
    background: var(--ds-light-bg-color);
    transition: transform var(--ds-main-transition), box-shadow var(--ds-main-transition);
    box-shadow: 0px 2px 14px 0px rgba(37, 38, 39, 0);
    overflow: hidden;
    transform: translateX(-100%);

    @media (min-width: $break-xl) {
        max-width: 460px;
    }

    &.active {
        transform: translateX(0);
        box-shadow: var(--ds-medium-shadow);
    }

    &-header {
        border-bottom: var(--ds-main-border);
        background: var(--ds-white-color);
        box-shadow: var(--ds-light-shadow);
        height: 3.275rem;

        &-back {
            line-height: 1.2;
            
            svg {
                pointer-events: none;

                path {
                    fill: var(--ds-dark-text-color);
                }
            }
        }
    }

    &-close {
        padding: 0.75rem;
    }

    &-content {
        height: 100%;
        max-height: calc(100% - 3.5rem);
        overflow-x: hidden;
        overflow-y: auto;

        .py-3.fw-500.dark-text.fsz-20 {
            display: none;
        }

        .ds-module-item:not(.product-layout) {
            width: 100%;

            .content-block {
                padding: 0.5rem;
                flex-direction: row !important;
                position: relative;
            }

            .ds-module-img {
                padding-right: 0.5rem;
                border-right: var(--ds-main-border);
                flex: 0 0 96px;

                img {
                    width: 88px;
                    height: 88px;
                }
            }

            .ds-module-stickers,
            .ds-module-buttons,
            .ds-module-code,
            .ds-module-stock,
            .ds-module-rating,
            .ds-module-quantity {
                display: none !important;
            }

            .ds-module-cart {
                position: absolute;
                bottom: 0.5rem;
                right: 0.5rem;
            }
        }
    }

    &-menu {
        &-links {
            background: var(--ds-white-color);

            &-item {
                padding: 0.75rem 1rem;

                &:not(:last-child) {
                    border-bottom: var(--ds-main-border);
                }

                &-icon {
                    margin-right: 1rem;
                    background: var(--ds-light-bg-color);
                    width: 1.75rem;
                    height: 1.75rem;
                }

                svg path {
                    fill: var(--ds-dark-text-color);
                }
            }
        }

        &-inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: transform var(--ds-main-transition);
            transform: translateX(100%);
            background: var(--ds-light-bg-color);

            &.active {
                transform: translateX(0);
            }
        }
    }
    
    [data-sidebar="close"] > * {
        pointer-events: none;
    }

    &#ds_sidebar_viewed {
        z-index: 14024;
        
        @media (min-width: $break-lg) {
            left: auto;
            right: 0;
            transform: translateX(100%);

            &.active {
                transform: translateX(0);
            }
        }

        .ds-module-caption {
            flex-grow: 1;
            padding-top: 0;
            padding-left: 0.5rem;
        }
    }
}