.ds-carousel {
    width: 100%;

    .slick-track {
        display: flex;

        @media (min-width: $break-md) {
            min-width: 100%;
        }
    }

    .slick-slide {
        margin: 0 0.25rem;
        height: auto;

        @media (min-width: $break-md) {
            margin: 0 0.5rem;
            min-width: 176px;
        }

        @media (min-width: $break-lg) {
            min-width: 149px;
        }

        @media (min-width: $break-xl) {
            min-width: 170px;
        }

        @media (min-width: $break-xxl) {
            min-width: 200px;
        }
    }

    .slick-list {
        margin: 0 -0.25rem;

        @media (min-width: $break-md) {
            margin: 0 -0.5rem;
            width: 100%;
        }
    }

    .slick-track {
        display: flex;
        align-items: stretch;
    }

    .content-block {
        transition: border-color var(--ds-main-transition);

        &:hover {
            box-shadow: none;
            border-color: #eaeaea;
            
            .ds-carousel-title {
                color: var(--ds-links-color);
            }
        }
    }

    &-title {
        font-size: 0.625rem;

        @media (min-width: $break-md) {
            font-size: 0.75rem;
        }
        
        &:hover {
            text-decoration: underline;
        }
    }

    margin-bottom: 2rem;
}